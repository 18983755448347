@charset "UTF-8";

@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700);

@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&subset=japanese);

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700);

.fadein {
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s;
}

.sec {
  max-width: 880px;
  margin: 80px auto 0;

  &.ptn01 {
    background: #000;
    max-width: 100%;
    margin: 0;
    padding-bottom: 100px;

    .mv {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 600px;
    }

    .inner {
      max-width: 880px;
      margin: -200px auto 0;
      position: relative;
      z-index: 2;

      .txtarea {
        background: #fff;
        padding: 50px 10%;
        box-sizing: border-box;

        h2 + P {
          margin-top: 40px;
          font-size: 14px;
          font-size: 1.4rem;

          + p {
            margin-top: 1em;
          }
        }
      }

      .btnarea {
        margin-top: 40px;

        .btn {
          width: 100%;

          &:after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            right: 30px;
            margin-top: -5px;
            border-style: solid;
            border-width: 1px 1px 0 0;
            border-color: #fff;
            transform: rotateZ(45deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .sec {
    margin: 40px auto 0;
  }
}

@media screen and (max-width: 769px) {
  .sec.ptn01 .mv {
    height: 280px;
  }
}

@media screen and (max-width: 980px) {
  .sec.ptn01 .inner {
    width: 90%;
    margin: -40px auto 0;
  }
}

@media screen and (max-width: 980px) {
  .sec.ptn01 .inner .txtarea {
    height: auto;
  }
}

@media screen and (max-width: 769px) {
  .sec.ptn01 .inner .txtarea {
    height: auto;
  }
}

@media screen and (max-width: 769px) {
  .sec.ptn01 .inner .txtarea h2 + P {
    margin-top: 20px;
  }
}

@media screen and (max-width: 769px) {
  .sec.ptn01 .inner .btnarea {
    margin-top: 20px;
  }
}

.subTtl {
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  font-family: "Noto Serif JP",serif;
  font-weight: 400;
  position: relative;
  line-height: 1;
  margin-bottom: 60px;

  &:after {
    content: "";
    width: 30px;
    height: 1px;
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &.ptn01:after {
    background: #bf000a;
    width: 78px;
  }

  + p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 320px) {
  .subTtl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

#english{
  .subTtl{
    line-height: 2.4rem;
  }
}

.btn {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  height: 63px;
  display: block;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  border-radius: 5px;
  line-height: 63px;
  color: #000;
  transition: all .3s ease;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  box-sizing: border-box;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -5px;
    border-style: solid;
    border-width: 1px 1px 0 0;
    border-color: #fff;
    transform: rotateZ(45deg);
  }
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
}

input {
  &[type=password], &[type=text] {
    background: #fff;
    border: 1px solid #ccc;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 40px;
  }
}

textarea {
  background: #fff;
  border: 1px solid #ccc;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 40px;
  height: 240px;
}

.select_label:focus {
  border: 1px solid #bf000a;
  outline: 0;
}

input {
  &[type=password]:focus, &[type=text]:focus {
    border: 1px solid #bf000a;
    outline: 0;
  }
}

textarea:focus {
  border: 1px solid #bf000a;
  outline: 0;
}

input[type=checkbox] {
  display: none;
}

.check_label {
  display: block;

  .checkbox01-parts {
    padding-left: 30px;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 22px;
      height: 22px;
      border: 1px solid #999;
      background: #fff;
      margin-top: -11px;
    }
  }
}

.checkbox01-input:checked + .checkbox01-parts:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 7px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 3px solid #bf000a;
  border-right: 3px solid #bf000a;
}

.select_label {
  background: #fff;
  border: 1px solid #ccc;
  width: 65%;
  height: 40px;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 5px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  z-index: 2;

  &:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #424242;
    display: block;
    position: absolute;
    margin-top: -4px;
    top: 50%;
    right: 10px;
    z-index: -1;
    content: "";
  }

  select {
    width: 150%;
    height: 30px;
    padding-right: 60px;
    box-sizing: border-box;
    font-size: 16px;
    white-space: normal;
    background: 0 0;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;

    &:focus {
      box-shadow: none;
    }
  }

  &.type01 {
    width: 40%;
  }

  &.type02 {
    width: 20%;
    margin-left: 20px;
  }

  + span {
    display: inline-block;
    vertical-align: middle;
  }
}

@media screen and (max-width: 769px) {
  .select_label {
    width: 100%;
  }
}

input[type=radio] {
  display: none;
}

.radio {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  margin-left: 20px;
  cursor: pointer;
  font-size: 16px;
  font-size: 1.6rem;

  &:hover:after {
    border-color: #bf000a;
  }

  &:after, &:before {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
  }

  &:after {
    left: 0;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 50%;
    box-sizing: border-box;
  }

  &:before {
    left: 6px;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    background: #bf000a;
    border-radius: 50%;
    opacity: 0;
  }

  .red{
    color:#bf000a;
  }
}

@media screen and (max-width: 769px) {
  .radio {
    display: block;
  }
}

input[type=radio]:checked + .radio:before {
  opacity: 1;
}

.from {
  margin: 80px auto 200px;
}

@media screen and (max-width: 769px) {
  .from {
    margin: 40px auto 100px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.from__flow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  li {
    font-size: 12px;
    font-size: 1.2rem;
    text-align: center;

    span {
      display: block;
      width: 40px;
      height: 40px;
      background: #bf000a;
      color: #fff;
      font-size: 20px;
      font-size: 2rem;
      text-align: center;
      line-height: 40px;
      border-radius: 50px;
    }

    + li {
      margin-left: 40px;

      span {
        background: #000;
        position: relative;

        &:before {
          content: "";
          width: 40px;
          height: 1px;
          background: #000;
          position: absolute;
          left: -40px;
          top: 50%;
        }
      }
    }

    &:nth-child(2) span:before {
      height: 2px;
      background: #bf000a;
      margin-top: -1px;
    }
  }

  + p {
    margin-top: 40px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 769px) {
  .from__flow + p {
    margin-top: 20px;
  }
}

.from__title__cf {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 40px;
  text-align: center;

  + p {
    margin-top: 20px;
    font-size: 14px;
    font-size: 1.4rem;
    text-align: center;

    + p {
      margin-top: 1em;
      font-size: 14px;
      font-size: 1.4rem;
      text-align: center;
    }
  }
}

@media screen and (max-width: 769px) {
  .from__title__cf {
    margin-top: 20px;
  }
}

.from dl {
  margin-top: 40px;

  dt {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 10px;

    .required {
      background: #bf000a;
      display: inline-block;
      margin-left: 20px;
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-weight: 400;
    }

    .attention {
      display: inline-block;
      color: #bf000a;
      font-size: 12px;
      font-size: 1.2rem;
      margin-left: 20px;
      font-weight: 400;
    }
  }

  dd {
    position: relative;
    font-size: 14px;
    font-size: 1.4rem;

    h3 {
      font-size: 14px;
      font-size: 1.4rem;
      margin-bottom: 10px;
      font-weight: 400;
    }

    + dt {
      margin-top: 40px;
    }

    input + h3 {
      margin-top: 20px;
    }

    .fieldErrorMessage {
      width: 70%;
      margin: -10px auto 0;
      background: #bf000a;
      padding: 10px;
      border-radius: 45px;
      color: #fff;
      font-size: 12px;
      font-size: 1.2rem;
      text-align: center;
      position: relative;
      z-index: 3;
      transition: all .3s ease;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #bf000a;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    p {
      font-size: 12px;
      font-size: 1.2rem;

      + h3 {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .from dl {
    margin-top: 20px;
  }
}

@media screen and (max-width: 769px) {
  .from dl dd + dt {
    margin-top: 20px;
  }
}

.from__error {
  background: #bf000a;
  color: #fff;
  padding: 40px 0;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 40px;

  span {
    background: #fff;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: block;
    color: #bf000a;
    border-radius: 50px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 769px) {
  .from__error {
    margin-top: 20px;
  }
}

.from {
  .btnarea {
    margin-top: 40px;

    .btn {
      background: #000;
      width: 100%;
      height: 63px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      font-size: 1.4rem;
      transition: all .3s ease;
      line-height: 63px;
      position: relative;
      border: none;

      &:before {
        content: "";
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        left: 40px;
        top: 50%;
      }

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        right: 30px;
        margin-top: -5px;
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: #fff;
        transform: rotateZ(45deg);
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  &.confirm {
    .from__flow li {
      &:nth-child(1) {
        color: #e3e3e3;

        span {
          background: #e3e3e3;
        }
      }

      &:nth-child(2) span {
        background: #bf000a;

        &:before {
          height: 1px;
          background: #e3e3e3;
          margin-top: 0;
        }
      }

      &:nth-child(3) span:before {
        height: 2px;
        background: #bf000a;
        margin-top: -1px;
      }
    }

    dl {
      dt {
        text-align: center;
      }

      dd {
        background: #fafafa;
        padding: 10px 20px;
        font-size: 16px;
        font-size: 1.6rem;

        &:nth-of-type(1) {
          background: 0 0;
          padding: 0;

          p {
            background: #fafafa;
            padding: 10px 20px;
            font-size: 16px;
            font-size: 1.6rem;
          }
        }
      }
    }

    .from__btnarea {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .btutton {
        width: 48%;

        .btn {
          width: 100%;
          border: 2px solid #000;
          background: #fff;
          color: #000;
          box-sizing: border-box;

          &:after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            right: 30px;
            margin-top: -5px;
            border-style: solid;
            border-width: 1px 1px 0 0;
            border-color: #fff;
            transform: rotateZ(45deg);
            right: auto;
            left: 30px;
            border-color: #000;
            transform: rotateZ(-135deg);
          }
        }
      }

      .btn {
        width: 48%;
      }
    }
  }

  &.fix .from__flow li {
    &:nth-child(1), &:nth-child(2) {
      color: #e3e3e3;

      span {
        background: #e3e3e3;

        &:before {
          background: #e3e3e3;
        }
      }
    }

    &:nth-child(3) span {
      background: #bf000a;

      &:before {
        height: 2px;
        background: #bf000a;
        margin-top: -1px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .from .btnarea {
    margin-top: 20px;
  }
}

.from__login__btnarea .btn {
  &:before {
    background-image: url(../images/index/icon_login.svg);
    width: 32px;
    height: 38px;
    margin-top: -19px;
  }

  &.top:before {
    display: none;
  }
}

.from .check_label {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}


/*# sourceMappingURL=sourcemaps/pages.css.map */
